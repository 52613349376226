import "./index.scss";

const Testimonials = () => {
  return (
    <section className="testimonials bg-[#f5f7fd] py-24 z-10">
      <div className="relative lg:w-2/5 w-4/5 text-center mx-auto">
        <img src="/coinflow/group-56.png" alt="quote" className="quote z-10" />
        <p className="text-lg text-[#383838] mb-6">
          Coinflow allows us to accept instant deposit from our users. It enables us to offer a new way to accept payment, and allows us to enter into markets where we don’t have local banking integrations with in a secure and cost-effective manner.
        </p>
        <h3 className="font-extrabold text-2xl text-[#0e4367]">Peter Johnson</h3>
        <hr className="mx-auto h-[.2rem] my-1 w-[12rem] bg-[#0e4367]" />
        <h4 className="font-medium text-normal text-[#0e4367]">CTO, Large Multinational.</h4>
      </div>
    </section>
  );
}

export default Testimonials;
