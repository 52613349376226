const Features = () => {
  return (
    <section className="features py-16" id="features">
      <h4 className="text-2xl text-center font-semibold text-[#0e4367] mb-12">Features</h4>
      <div className="w-9/12 mx-auto block">
        <div className="flex lg:flex-row flex-col mb-6 items-center">
          <div className="lg:w-1/2 w-full lg:pr-16 lg:mb-0 mb-4">
            <h4 className="text-xl font-medium mb-6 text-[#0e4367]">Accept crypto payments</h4>
            <p className="text-normal text-sm text-[#383838]">
              Accept the most popular cryptocurrencies for your product or service, or allow your customers to deposit to your account anytime,
              for any amount. Keep it in your secured wallets, or exchange your earnings into stable coins.
            </p>
          </div>
          <div className="lg:w-1/2 w-full">
            <img src="/coinflow/new-assets/Features-first.svg" alt="Illustration" className="w-4/5 mx-auto lg:mx-0" />
          </div>
        </div>

        <div className="flex lg:flex-row flex-col mb-6 items-center">
          <div className="lg:w-1/2 lg:pr-16 lg:order-1 order-2">
            <img src="/coinflow/new-assets/Features-Second.svg" alt="Illustration" className="w-4/5 lg:float-right float-none mx-auto lg:mx-0" />
          </div>
          <div className="lg:w-1/2 w-full lg:order-2 order-1 lg:mb-0 mb-4">
            <h4 className="text-xl font-medium mb-6 text-[#0e4367]">Trade with ease</h4>
            <p className="text-normal text-sm text-[#383838]">
              Sell your cryptocurrencies for stable coins automatically based on the limit you set, or manually at any time. Our system is integrated with major exchanges and allow you to choose where to trade.
            </p>
          </div>
        </div>
        
        <div className="flex lg:flex-row flex-col mb-6 items-center">
          <div className="lg:w-1/2 w-full lg:pr-16 lg:mb-0 mb-4">
            <h4 className="text-xl font-medium mb-6 text-[#0e4367]">Multi-layer security and compliance</h4>
            <p className="text-normal text-sm text-[#383838]">
              Your fund is secured with Multi-Signature Wallet to ensure there is no single point of comprise. All the transactions are run through rigid AML controls to ensure the fund you receive is clean. We also continuously monitor activities on all accounts to identify suspicious behavior. 
            </p>
          </div>
          <div className="lg:w-1/2 w-full">
            <img src="/coinflow/new-assets/Features-third.svg" alt="Illustration" className="w-4/5 mx-auto lg:mx-0" />
          </div>
        </div>

        <div className="flex lg:flex-row flex-col mb-6 items-center">
          <div className="lg:w-1/2 w-full lg:pr-16 lg:order-1 order-2">
            <img src="/coinflow/new-assets/Features-Fourth.svg" alt="Illustration" className="w-4/5 lg:float-right float-none mx-auto lg:mx-0" />
          </div>
          <div className="lg:w-1/2 w-full lg:order-2 order-1 lg:mb-0 mb-4">
            <h4 className="text-xl font-medium mb-6 text-[#0e4367]">Comprehensive reports </h4>
            <p className="text-normal text-sm text-[#383838]">
              All-around reports that allow you to understand everything that goes on inside your account. Visual presentation of the transactions that gives you clear insights on the performance of your product or service.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;