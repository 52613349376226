import { Fragment, useState } from "react";
import Header from "../../components/Header";
import HowItWorks from "../../components/HowItWorks";
import SupportedCoins from "../../components/SupportedCoins";
import Features from "../../components/Features";
import Testimonials from "../../components/Testimonials";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import ContactForm from "../../components/ContactForm";
import "./index.scss";

const Home = () => {
  const [modal, setModal] = useState(false);

  return (
    <Fragment>
      <Modal title="" size="large" isOpen={modal} handleClose={() => setModal(false)}>
        <div className="w-full bg-white text-center">
          <h3 className="text-[#0e4367] font-semibold text-[1.5rem] text absolute centralized__title capitalize">Book your Demo</h3>
          <p className="text-center lg:w-9/12 mx-auto text-sm text-[#373f41]">Once we receive your details, one of our specialists will contact you as soon as possible.</p>
          <ContactForm />
        </div>
      </Modal>
      <section className="hero__section relative">
        <Header handleClose={() => setModal(false)} handleOpen={() => setModal(!modal)} />
        <img src="/coinflow/group.png" alt="Coinflow" className="coinflow__layer" />
        <div className="container pt-24 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center hero__main">
          <div className="hero__content lg:flex grid content-around flex-col lg:flex-row lg:items-center lg:px-16 mt-28 lg:w-full h-[70vh]">
            <div className="lg:w-1/2 w-full lg:mr-16 lg:mb-0 mb-6">
              <h4 className="uppercase lg:text-4xl text-2xl lg:text-left text-center tracking-wider text-white leading-tight z-10"><span className="text-yellow-300">Crypto payment</span> gateway.</h4>
              <p className="text-white mt-6 lg:text-lg text-sm font-light lg:text-left text-center z-10">Receive, store, and pay in cryptocurrency, all in one place. Take advantage of our secure, flexible, and low-fee payment system.</p>
              <button type="button" onClick={() => setModal(!modal)} className="px-10 py-3 lg:mt-12 mt-6 w-1/2 lg:mx-0 mx-auto block text-xs text-gray-700 font-semibold uppercase rounded-full bg-yellow-300 hover:bg-yellow-400 transition-all z-10 lg:block hidden">Book A Demo</button>
            </div>
            <div className="lg:w-1/2 w-full lg:ml-16">
              <img src="/coinflow/header-illustration.png" alt="Coinflow Illustration" />
            </div>
          </div>
        </div>
      </section>
      <HowItWorks handleOpen={() => setModal(true)} />
      <SupportedCoins />
      <Features />
      <Testimonials />
      <Footer />
    </Fragment>
  );
}

export default Home;
