import "./index.scss";

const assets = [
  {
    code: "BTC",
    name: "Bitcoin",
    image: "/assets/BTC.svg",
  },
  {
    code: "ETH",
    name: "Ethereum",
    image: "/assets/ETH.svg",
  },
  {
    code: "USDT",
    name: "USDT",
    image: "/assets/USDT.svg",
  },
  {
    code: "BNB",
    name: "Binance Coin",
    image: "/assets/BNB.png",
  },
  {
    code: "USDC",
    name: "USDC",
    image: "/assets/USDC.svg",
  },
  {
    code: "BUSD",
    name: "Binance USD",
    image: "/assets/BUSD.png",
  },
  {
    code: "BCH",
    name: "Bitcoin Cash",
    image: "/assets/BCH.svg",
  },
  {
    code: "LTC",
    name: "Litecoin",
    image: "/assets/LTC.png",
  },
  {
    code: "DAI",
    name: "Dai Stablecoin",
    image: "/assets/DAI.png",
  },
  {
    code: "SHIB",
    name: "Shiba Inu",
    image: "/assets/SHIB.png",
  },
];

const SupportedCoins = () => {
  return (
    <section className="supported__coins bg-[#f5f7fd] pb-16 pt-10" id="currencies">
      <h4 className="font-semibold text-2xl text-center text-[#0e4367] lg:mb-12 mb-10">Supported Coins</h4>

      <div className="lg:w-9/12 w-full lg:px-0 px-4 mx-auto">
        <div className="d-flex flex justify-center flex-wrap">
          {assets.map((asset) => (
            <div className="lg:w-[10%] md:w-1/4 w-1/3 mb-12 flex lg:flex-row flex-col lg:items-center lg:justify-start justify-center items-start mx-auto">
              <div className="flex flex-col w-full text-center mx-auto">
                <div className="flex justify-center">
                  <img src={asset?.image} alt={asset?.name} className="w-[3rem] h-[3rem] mb-2" />
                </div>
                <div className="w-auto text-[#0e4367] w-full lg:text-left text-center lg:mt-0 mt-2 text-center">
                  <h4 className="font-bold text-center text-[1.2rem]">{asset?.code}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SupportedCoins;
