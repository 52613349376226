import { Link } from "react-scroll";
import history from "../../history";

const Footer = () => {
  const currentTime = new Date();

  const navigateTo = (link) => history.push(link)

  return (
    <footer className="bg-[#0e4367] text-white">
      <div className="flex justify-between w-10/12 mx-auto py-12">
        <div className="block">
          <Link to="home" className="cursor-pointer" duration={300} smooth={false}>
            <img src="/logo.svg" alt="Coinflow" className="lg:w-[150px] w-[120px]" />
          </Link>
          <div className="mt-4 text-sm">© CoinFlow {currentTime.getFullYear()}</div>
        </div>
        <div className="block text-white">
          <h4 className="capitalize font-semibold">Products</h4>
          <div className="block">
            <p className="my-2 font-light text-sm">
              <Link to="how-it-works" className="cursor-pointer" offset={-80} duration={300} smooth={false}>
                How it Works
              </Link>
            </p>
            <p className="my-2 font-light text-sm">
              <Link to="currencies" className="cursor-pointer" offset={-80} duration={300} smooth={false}>
                Currencies
              </Link>
            </p>
            <p className="my-2 font-light text-sm">
              <Link to="features" className="cursor-pointer" offset={-80} duration={300} smooth={false}>
                Features
              </Link>
            </p>
          </div>
        </div>
        <div className="block text-white">
          <h4 className="capitalize font-semibold">Company</h4>
          <div className="block">
            <p className="my-2 font-light text-sm">
              <Link to="/" className="cursor-pointer" onClick={() => navigateTo('about-us')}>
                About Us
              </Link>
            </p>
            <p className="my-2 font-light text-sm">
              <Link to="/" className="cursor-pointer" onClick={() => navigateTo('contact-us')}>
                Contact Us
              </Link>
            </p>
            {/* <p className="my-2 font-light text-sm">
              <Link to="/" className="cursor-pointer" onClick={() => navigateTo('terms-and-conditions')}>
                Terms and Conditions
              </Link>
            </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
