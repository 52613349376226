import "./index.scss";

const HowItWorks = ({ handleOpen }) => {
  return (
    <section className="how-it-works lg:w-10/12 w-full mx-auto py-12 text-center" id="how-it-works">
      <h4 className="text-2xl capitalize font-semibold text-[#0e4367]">How It Works</h4>
      <div className="flex flex-wrap lg:mb-24 mb-4 mt-16">
        <div className="lg:w-1/4 lg:mb-0 flex lg:flex-col flex-row items-center w-full lg:px-6 px-8 mx-auto works__item">
          <div className="lg:w-[250px] w-[80px] h-[80px] flex justify-center items-center lg:border-none border-2 rounded-full border-[#0e4367]">
            <img src="/coinflow/group-22.png" alt="How it works" className="mx-auto lg:mb-8 lg:w-1/4 w-[40px]" /> 
          </div>
          <p className="lg:w-10/12 w-9/12 lg:pl-0 pl-6 mx-auto text-[#383838]">Your customers pay for your product or service, or deposit without a given amount with a cryptocurrency of their choice.</p>
        </div>
        <div className="divider lg:hidden block">
          <div className="splits" />
        </div>
        <div className="lg:w-1/4 lg:mb-0 mb-8 flex lg:flex-col flex-row items-center w-full lg:px-6 px-8 mx-auto works__item">
          <div className="lg:w-[250px] w-[80px] h-[80px] flex justify-center items-center lg:border-none border-2 rounded-full border-[#0e4367]">
            <img src="/coinflow/group-46.png" alt="How it works" className="mx-auto lg:mb-8 lg:w-1/4 w-[40px]" />
          </div>
          <p className="lg:w-10/12 w-9/12 lg:pl-0 pl-6 mx-auto text-[#383838]">You accept crypto payments into your wallets.</p>
        </div>
        <div className="divider lg:hidden block">
          <div className="splits" />
        </div>
        <div className="lg:w-1/4 lg:mb-0 mb-8 flex lg:flex-col flex-row items-center w-full lg:px-6 px-8 mx-auto works__item">
          <div className="lg:w-[250px] w-[80px] h-[80px] flex justify-center items-center lg:border-none border-2 rounded-full border-[#0e4367]">
            <img src="/coinflow/group-50.png" alt="How it works" className="mx-auto lg:mb-8 lg:w-1/3 w-[50px]" />
          </div>
          <p className="w-10/12 w-9/12 mx-auto text-center text-[#383838] lg:pl-0 pl-6">Based on a set of configurable rules, or anytime you want, trade the cryptos to stable coins.</p>
        </div>
        <div className="divider lg:hidden block">
          <div className="splits" />
        </div>
        <div className="lg:w-1/4 lg:mb-0 mb-8 flex lg:flex-col flex-row items-center w-full lg:px-6 px-8 mx-auto works__item">
          <div className="lg:w-[200px] lg:h-[75px] w-[80px] h-[80px] flex justify-center items-center lg:border-none border-2 rounded-full border-[#0e4367]">
            <img src="/coinflow/group-24.png" alt="How it works" className="mx-auto lg:mb-8 lg:h-full h-1/5 lg:w-1/3 w-[40px] h-[40px]" />
          </div>
          <p className="w-10/12 w-9/12 mx-auto text-center text-[#383838] lg:pl-0 pl-6">Withdraw from the exchange to your balance based on our automated rule engine, or anytime you want.</p>
        </div>
      </div>
      <div className="w-full mx-auto text-center">
        <button type="button" onClick={handleOpen} className="uppercase rounded-full text-white text-sm px-10 py-2.5">Book A Demo</button>
      </div>
    </section>
  );
}

export default HowItWorks;
