import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { syncTranslationWithStore } from "react-redux-i18n";
import pipelineApp from "./reducers";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/all.css";
import "./index.css";

const isProduction = process.env.NODE_ENV === "production";

// Creating store
let store = null;

if (isProduction) {
  // In production adding only thunk middleware
  /* const middleware = applyMiddleware(thunk)(createStore);
  store = createStore(pipelineApp, middleware);*/

  const middleware = applyMiddleware(thunk);
  const enhancer = compose(middleware);
  store = createStore(pipelineApp, enhancer);
} else {
  // In development mode beside thunk
  // DevTools are added
  let composeEnhancers = compose;
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const logger = createLogger();
  const middleware = [applyMiddleware(thunk), applyMiddleware(logger)];
  const enhancer = composeEnhancers(...middleware);
  store = createStore(pipelineApp, enhancer);
}

syncTranslationWithStore(store);
localStorage.setItem('lang', 'en');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routes />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
