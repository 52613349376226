/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import ReactPortal from "../Portal";
import "./index.scss";

const Modal = ({ children, title, size = "normal", isOpen, handleClose }) => {
	const nodeRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOut);
  }, []);

	useEffect(() => {
		const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
		document.body.addEventListener("keydown", closeOnEscapeKey);
		return () => {
			document.body.removeEventListener("keydown", closeOnEscapeKey);
		};
	}, [handleClose]);

  const handleClickOut = (event) => {
    if (nodeRef && !nodeRef?.current?.contains(event.target)) {
      handleClose();
    };
  };

  if (!isOpen) return null;

	return (
		<ReactPortal wrapperId="react-portal-modal-container">
      <div className="demo__modal">
        <div className={`modal__content ${size === "normal" ? 'size__normal' : 'size__large'}`} ref={nodeRef}>
          <div className="modal__container relative">
            <div className="modal__header">
              <div className="modal__title">
                <h3>{title}</h3>
              </div>
              <span className="relative z-20" onClick={() => handleClose()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="modal__close">
                  <path d="M19.003 6.42l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z" fill="currentColor"></path>
                </svg>
              </span>
            </div>
            <div className="withdawal__content">
              {children}
            </div>
          </div>
        </div>
      </div>
		</ReactPortal>
	);
}
export default Modal;
