import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import history from "./history";
import Home from "./views/Home";
import ContactUs from "./views/ContactUs";
import AboutUs from "./views/AboutUs";
// import TermsAndConditions from "./views/TermsAndConditions";

const Routes = () => {
  history.listen(() => {
    // location is an object like window.location
    window.scrollTo(0, 0);
  });

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/about-us" component={AboutUs} />
        {/* <Route exact path="/terms-and-conditions" component={TermsAndConditions} /> */}
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;