import React, { useState, useEffect, useRef } from "react";
import { Link, scroller, Element } from "react-scroll";
import history from "../../history";
import "./index.scss";

const Header = ({ handleClose, handleOpen }) => {
  let myRef = useRef(null);
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOut);
  }, []);

  const handleClickOut = (event) => {
    if (myRef && !myRef?.current?.contains(event.target)) {
      setSidebar(false);
    };
  };

  const afterClick = (name) => {
    const { location: { pathname } } = history;
    if (pathname !== "/") {
      history.push('/');
      setTimeout(() => {
        scroller.scrollTo(name, {
          offset: -80,
          duration: 200
        })
      }, 100);
    }
    setSidebar(false);
  }

  const navigateToContact = () => {
    history.push("/contact-us");
    setSidebar(false);
  }

  return (
    <Element name="home">
      <div className="w-full block header mx-auto py-4 fixed bg-[#0e4367] pin-t">
        <div className={`min-h-screen lg:hidden block bg-white w-[250px] fixed top-0 bottom-0 left-0 mobile-sidebar bg-white z-[99] ${sidebar ? "enter-header" : 'hide-header'}`} ref={myRef}>
          <div className="w-full py-8 flex flex-col content-between h-[95vh]">
            <div className="flex items-center justify-between px-6">
              <Link to="home" onClick={() => {
                setSidebar(false);
              }}>
                <img src="/coinflow/coinflow-blue-logo.svg" alt="Coinflow" className="lg:w-[150px] w-[100px]" />
              </Link>
              <i className="fa fa-times-thin cursor-pointer text-slate-500 text-xl" onClick={() => setSidebar(false)} />
            </div>
            <div className="mt-12 flex flex-col">
              <Link to="home" className="sidebar-links" onClick={() => afterClick('home')}>
                Home
              </Link>
              <Link duration={300} offset={-80} to="how-it-works" className="sidebar-links" onClick={() => afterClick('how-it-works')}>
                How It Works
              </Link>
              <Link duration={300} offset={-80} to="currencies" className="sidebar-links" onClick={() => afterClick('currencies')}>
                Currencies
              </Link>
              <Link duration={300} offset={-80} to="features" className="sidebar-links" onClick={() => afterClick('features')}>
                Features
              </Link>
              <Link to="" className="sidebar-links" onClick={navigateToContact}>
                Contact Us
              </Link>
            </div>
          </div>
          <div className="px-8">
            <button type="button" className="book__demo lg:px-8 px-6 lg:py-3 py-2 text-xs text-gray-700 font-semibold uppercase rounded-full bg-yellow-300 hover:bg-yellow-400 transition-all" onClick={handleOpen}>Book A Demo</button>
          </div>
        </div>
        <div className="w-full header-desktop flex items-center justify-between">
          <div className="flex items-center">
            <i className="fa fa-bars cursor-pointer lg:hidden md:hidden block mr-4 text-white z-20" onClick={() => setSidebar(true)} />
            <Link duration={300} to="home" className="flex items-center no-underline hover:no-underline font-bold text-2xl lg:text-4xl cursor-pointer relative" onClick={() => history.push("/")}>
              <img src="/logo.svg" alt="Coinflow" className="lg:w-[150px] w-[100px] relative" />
            </Link>
          </div>
          <div className="header-links flex justify-end items-center">
            <Link duration={300} offset={-80} to="how-it-works" className="capitalize lg:block hidden mx-5 text-white text-sm font-semibold cursor-pointer" onClick={() => afterClick('how-it-works')}>
              How It Works
            </Link>
            <Link duration={300} offset={-80} to="currencies" className="capitalize lg:block hidden mx-5 text-white text-sm font-semibold cursor-pointer" onClick={() => afterClick('currencies')}>
              Currencies
            </Link>
            <Link duration={300} offset={-80} to="features" className="capitalize lg:block hidden mx-5 text-white text-sm font-semibold cursor-pointer" onClick={() => afterClick('features')}>
              Features
            </Link>
            <button type="button" className="book__demo lg:px-8 px-6 lg:py-3 py-2 text-xs text-gray-700 font-semibold uppercase rounded-full bg-yellow-300 hover:bg-yellow-400 transition-all z-50" onClick={handleOpen}>Book A Demo</button>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default Header;
