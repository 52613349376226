import { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import ContactForm from "../../components/ContactForm";
import "./index.scss";

const AboutUs = () => {
  const [modal, setModal] = useState(false);

  return (
    <div className="body__container">
      <Modal title="" size="large" isOpen={modal} handleClose={() => setModal(false)}>
        <div className="w-full bg-white text-center">
          <h3 className="text-[#0e4367] font-semibold text-[1.5rem] text absolute centralized__title capitalize">Book your Demo</h3>
          <p className="text-center lg:w-9/12 mx-auto text-sm text-[#373f41]">Once we receive your details, one of our specialists will contact you as soon as possible.</p>
          <ContactForm />
        </div>
      </Modal>
      <section className="hero__section relative">
        <Header handleClose={() => setModal(false)} handleOpen={() => setModal(!modal)} />
      </section>
      <section className="about__hero">
        <img src="/coinflow/new-assets/About-Us-Header-Graphics.svg" alt="About Us" />
      </section>
      <section className="about__section">
        <h2 className="title">About Coinflow</h2>
        <p>
          <b>Coinflow</b> is a compenhensive cryptocurrency payment solution dedicated to online gaming, betting, and streaming industries. Our mission is to unleash the unlimited possibilities of cryptocurrency. Our goal is to empower businesses worldwide to handle instant payments, deposits, and withdrawals in a secured manner.
        </p>
        <p>
          <b>Our team</b> is composed of experts in blockchain & cryptocurrency, payments & financial services, as well as technology development. We value individual contribution as well as team work. Individually, we are the masters at our craft; together, we form an agile team that can adapt to any changes.
        </p>
      </section>
      <section className="core__values">
        <div className="core__container">
          <h2 className="title">Our Core Values</h2>

          <div className="values">
            <div className="value">
              <div className="desc">
                <h3 className="title">Security</h3>
                <p>We use industry-leading AML providers to make sure the fund you receive is legit. And our multi-layer security structure ensures there is no single point of comprise.</p>
              </div>
              <img src="/coinflow/new-assets/About-Us-Security.svg" alt="Security" />
            </div>
            <div className="value">
              <img src="/coinflow/new-assets/About-Us-Transparency.svg" alt="Security" className="lg:order-1 order-2" />
              <div className="desc lg:order-2 order-1">
                <h3 className="title">Transparency</h3>
                <p>We offers a SaaS platform for our merchants to manage their assets. Our transparent fee structure and real-time fee calculation guarantee what you see is what you get.</p>
              </div>
            </div>
            <div className="value">
              <div className="desc">
                <h3 className="title">Flexibility</h3>
                <p>We enable our merchants to trade their crypto assets based on rules they set themselves, or manually at anytime they want. We have an easy onboarding process to get you up and running with accepting crypto payments.</p>
              </div>
              <img src="/coinflow/new-assets/About-Us-Flexibility.svg" alt="Security" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AboutUs;
