import React, { Component } from "react";
import axios from "axios";
import "./index.scss";

class ContactForm extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    message: "",
    company: "",
    website: "",
    isError: false,
    loading: false,
    website_error: false,
    email_error: false,
    successMessage: "",
    errorMessage: "",
  };

  componentWillUnmount() {
    this.resetFields();
  };

  validURL =(str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  handlSubmit = (e) => {
    e.preventDefault();
    const { email, website, message, phone, company } = this.state;
    var re = /\S+@\S+\.\S+/;
    let email_verify = re.test(email);
    let website_error =this.validURL(website);
    if (!email_verify && email) {
      this.setState({
        email_error: true
      });
    };
    if (!website_error) {
      this.setState({
        website_error: true
      });
    }

    if (website && email_verify && website_error && message && company && (!phone || this.phoneValidation(phone))) {
      this.setState({
        email_error: false,
        loading: true,
      }, () => {
        this.sendMail();
      });
    } else {
      this.setState(
        {
          isError: true,
          loading: false
        }
      );
    };
  };

  sendMail = () => {
    const { email, website, message, company, phone } = this.state;
    axios({
      method: 'POST',
      url: 'https://formspree.io/f/xayvbrog',
      data: {
        name: company,
        email,
        phone,
        message: `${message} \n \n Website: ${website}`,
      }
    })
    .then(() => {
      this.resetFields();
      this.setState({
        successMessage: "Your message has been sent. We will be in touch with you shortly."
      })
    })
    .catch((err) => {
      this.setState({
        loading: false,
        errorMessage: "Something went wrong!"
      }, () => {
        setTimeout(() => {
          this.setState({
            errorMessage: ""
          });
        }, 8000)
      });
    });
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.currentTarget.name === "email") {
      var re = /\S+@\S+\.\S+/;
      let email_verify = re.test(e.currentTarget.value);
      if (email_verify) {
        this.setState({
          email_error: false
        });
      } else {
        this.setState({
          email_error: true
        });
      };
    };
    if (e.currentTarget.name === "website") {
      let website_verify = this.validURL(e.currentTarget.value);
      if (website_verify) {
        this.setState({
          website_error: false
        });
      };
    }
    if (e.currentTarget.name === "phone")  {
      if (this.validatePhoneInput(value) || value === "") {
        this.setState({
          [name]: value.replace(/  +/g, " ")
        });
      }
    } else {
      this.setState({
        [name]: value.replace(/  +/g, " ")
      });
    }
  };

  validatePhoneInput = (phone) => {
    const validChars = ['+', ' ', '(', ')', '-'];
    const index = phone.length - 1;
    const input = phone.charAt(index)
    return validChars.includes(input) || !isNaN(input);
  }

  phoneValidation = (phone) => {
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    return regex.test(phone) && phone.length > 10;
  }

  resetFields = () => {
    this.setState({
      company: "",
      email: "",
      message: "",
      phone: "",
      website: "",
      email_error: false,
      website_error: false,
      isError: false,
      loading: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          successMessage: ""
        });
      }, 8000);
    });
  }

  render() {
    const {
      email,
      phone,
      company,
      website,
      message,
      isError,
      loading,
      email_error,
      website_error,
      successMessage,
      errorMessage
    } = this.state;

    return (
      <form className="lg:w-4/5 w-full mt-8 mx-auto" onSubmit={this.handlSubmit} noValidate>
        {successMessage && (
          <div className="flex items-center bg-green-500 text-white text-sm font-medium px-4 py-3 text-left mb-4 rounded" role="alert">
            <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
            <p>{successMessage}</p>
          </div>
        )}
        {errorMessage && (
          <div className="flex items-center bg-red-400 text-white text-sm font-medium px-4 py-3 text-left mb-4 rounded" role="alert">
            <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
            <p>{errorMessage}</p>
          </div>
        )}
        <div className="form-group mb-4">
          <label htmlFor="companyName" className="block text-left mb-1 text-gray-500 text-sm">Company name</label>
          <input type="text" id="companyName" name="company" value={company} onChange={this.handleChange} className="rounded text-sm border-[#0e4367] border w-full px-3 py-2 outline-none mb-1" />
          {isError && !company && (
            <span className="text-red-400 text-xs text-left block">Company name is required</span>
          )}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="website" className="block text-left mb-1 text-gray-500 text-sm">Website</label>
          <input type="text" id="website" name="website" value={website} onChange={this.handleChange} className="rounded text-sm border-[#0e4367] border w-full px-3 py-2 outline-none mb-1" />
          {isError && !website && (
            <span className="text-red-400 text-xs text-left block">Website field is required</span>
          )}
          {((isError && website) && website_error) && (
            <span className="text-red-400 text-xs text-left block">Please enter a valid website</span>
          )}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="email" className="block text-left mb-1 text-gray-500 text-sm">Business email</label>
          <input type="email" id="email" name="email" value={email} onChange={this.handleChange} className="rounded text-sm border-[#0e4367] border w-full px-3 py-2 outline-none mb-1" />
          {isError && !email && (
            <span className="text-red-400 text-xs text-left block">Email field is required</span>
          )}
          {((isError && email) && email_error) && (
            <span className="text-red-400 text-xs text-left block">Please enter a valid email address</span>
          )}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="phone" className="block text-left mb-1 text-gray-500 text-sm">Phone (optional)</label>
          <input type="tel" name="phone" id="phone" value={phone} onChange={this.handleChange} className="rounded text-sm border-[#0e4367] border w-full px-3 py-2 outline-none mb-1" />
          {isError && phone && !this.phoneValidation(phone) && (
            <span className="text-red-400 text-xs text-left block">Please enter a valid phone number</span>
          )}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="message" className="block text-left mb-1 text-gray-500 text-sm">Message</label>
          <textarea name="message" id="message" value={message} onChange={this.handleChange} className="rounded text-sm border-[#0e4367] border w-full px-3 py-2 outline-none" rows="7"></textarea>
          {isError && !message && (
            <span className="text-red-400 text-xs text-left block">Message field is required</span>
          )}
        </div>
        <button type="submit" disabled={loading} className="lg:px-16 lg:py-3 px-12 py-2 uppercase rounded-full text-sm text-white bg-[#0e4367] cursor-pointer">
          {loading ? (
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : "Send"}
        </button>
      </form>
    );
  };
};

export default ContactForm;
