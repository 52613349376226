import { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import ContactForm from "../../components/ContactForm";
import "./index.scss";

const ContactUs = () => {
  const [modal, setModal] = useState(false);

  return (
    <div className="body__container">
      <Modal title="" size="large" isOpen={modal} handleClose={() => setModal(false)}>
        <div className="w-full bg-white text-center">
          <h3 className="text-[#0e4367] font-semibold text-[1.5rem] text absolute centralized__title capitalize">Book your Demo</h3>
          <p className="text-center lg:w-9/12 mx-auto text-sm text-[#373f41]">Once we receive your details, one of our specialists will contact you as soon as possible.</p>
          <ContactForm />
        </div>
      </Modal>
      <section className="hero__section relative">
        <Header handleClose={() => setModal(false)} handleOpen={() => setModal(!modal)} />
      </section>
      <section className="contact-us">
        <div className="desc">
          <h2 className="title">Get in touch with us</h2>
          <p>Once we receive your details, one of our specialists will contact you as soon as possible.</p>

          <h3>Or email us at</h3>
          <a href="mailto:info@coinflow.com">info@coinflow.com</a>
        </div>
        <div className="contact_form">
          <ContactForm />
        </div>
        {/* <div className="contact_form">
          <ContactForm />
        </div> */}
      </section>
      <Footer />
    </div>
  );
}

export default ContactUs;
